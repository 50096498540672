import React from "react"
import "./contact.css"
import instagram from "../images/instagram.svg"

const Contact = () => (
  <section className="section contact">
    <div className="contact__content">
      <h2>Wil je graag iets delen?</h2>
      <p>
        Zin in een babbeltje, heb je een vraag, een suggestie voor een nieuwe
        aflevering of kom je graag eens langs? Stuur ons een mailtje of zwaai
        eens via Instagram.
      </p>
    </div>
    <div className="contact__info">
      <a href="mailto:hallo@nucompany.be">hallo@nucompany.be</a>
      <ul className="contact__info__socials">
        <li>
          <a
            href="https://www.instagram.com/benner.nu"
            target="__blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="Instagram logo" />
          </a>
        </li>
      </ul>
    </div>
  </section>
)

export default Contact
