import React from "react"
import Layout from "../components/layout"
import Contact from "../components/contact"
import SEO from "../components/seo"
import Podcast from "../components/podcast"
import Benner from "../components/benner"

const IndexPage = () => (
  <Layout>
    <SEO title="Benner" lang="nl" />
    <Benner />
    <Podcast />
    <Contact />
  </Layout>
)

export default IndexPage
