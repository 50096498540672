import React from "react"

import podcastPreviewImage from "../images/podcast-preview.svg"
import "./podcast.css"
import listenOnApplePodcast from "../images/listen-on-apple-podcast.svg"
import listenOnSpotify from "../images/listen-on-spotify.svg"

const Podcast = () => {
  return (
    <section className="section podcast">
      <div className="podcast-image-preview">
        <img src={podcastPreviewImage} alt="Podcast preview" />
        <div className="podcast-image-preview__background"></div>
      </div>
      <div className="podcast-content">
        <h2>De podcast</h2>
        <small className="sub-title">Luister je mee?</small>
        <p>
          Vind ons op Spotify, Apple Podcasts, Google Podcasts. En hou je vooral
          niet in om ons te laten weten wat je ervan vindt. Tot zo?
        </p>
        <div className="podcast-content__links">
          <a
            href="https://open.spotify.com/show/5CLNTELXsSIiKSbWioVNID"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={listenOnSpotify} alt="Listen on spotify badge" />
          </a>
          <a
            href="https://podcasts.apple.com/be/podcast/benner/id1551776711"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={listenOnApplePodcast}
              alt="Listen on apple podcast badge"
            />
          </a>
        </div>
      </div>
    </section>
  )
}

export default Podcast
