import React from "react"

import "./benner.css"
import GroupImage from "../images/group-image.png"

const Benner = () => {
  return (
    <section className="section benner">
      <div className="benner-img">
        <img src={GroupImage} alt="Foto Annelies en Lien" />
      </div>
      <div className="benner-content">
        <h2>benner.</h2>
        <small className="sub-title">Een podcast over zijn. Hier en nu.</small>
        <p>
          Wij zijn Lien en Annelies. We houden van goeie babbels en willen deze
          keigraag delen. We hebben het over zijn, het hier en nu, over
          onzekerheden, ons ego, angstjes en hoe we ons voelen, over doen en
          denken, over gewoontes, tijd en prioriteit, over stemmetjes, pieken,
          dalen en golfkes, over spelen en terug kind zijn, over je goed voelen
          in je eigen lichaam, over kleine stappen zetten, doelen bereiken en
          vieren.
        </p>
      </div>
    </section>
  )
}

export default Benner
